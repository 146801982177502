import request from '@/utils/axios'

export const login = (data) => {
  return request({
    url: '/login/',
    method: 'post',
    data
  })
}

export const userInfo = (data) => {
  return request({
    url: '/userinfo/',
    method: 'get',
    params: data
  })
}

export const upload = (data) => {
  return request({
    url: '/uploadfile/',
    method: 'post',
    data
  })
}

export function logout () {
  return request({
    url: '/logout/',
    method: 'get'
  })
}

export function userList (params) {
  return request({
    url: '/users/',
    method: 'get',
    params
  })
}
